<template>
    <div class="container">
        <div id="wrapper">
            <div id="content" class="row">
                <div class="col-md-3">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header"  class="table mb-0 h9 " cellpadding="2" cellspacing="4" border="0" clas>
                            <thead>
                                <tr class="text-center">
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="ms-4">{{fl.name}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                        <table class="table table-bordered">
                            <tbody >
                            <tr v-for="(dtl, index) in recs" :key="index" class="h9">
                                    <div class="row">
                                        <div class="col-8">{{dtl.ts_name}}</div>
                                        <div class="col-2">
                                            <button @click="showDetail(dtl)" class="btn btn-success btn-sm mb-3"><i class="fa fa-eye"></i></button>
                                        </div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-9">

                        <div class="fw-bold my-3">
                            Nama Sales : {{ recnama }}
                        </div>

                    <table class="table table-hover table-responsive" id="ratio" style="cursor:pointer">
                        <thead>
                            <tr class="table-head">
                                <th class="">Nama</th>
                                <th class="">{{year}}</th>
                                <th class="">{{yearSub}}</th>
                                <th class="">persentase</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";
import moment from "moment";
import {Money} from 'v-money';
import { ModelListSelect } from "vue-search-select";
import DatePicker from "@/components/DatePicker.vue";
import * as XLSX from 'xlsx';

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Target',
    components: {
        Toolbar,
        Money,
        DatePicker,
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            month : moment(new Date()).format('MMM'),
            year : moment(new Date()).format('YYYY'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            yearSub : moment().subtract(1, 'year').format('YYYY'),
            showdate :{tg_date:false},
            rec: {},
            recs: [],
            recDetails : [],
            recsratiocust : [],
            recDtlss : [],
            recItems : [],
            recnama : {},
            recsSales : [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'ts_name', type: 'text', name : 'Nama Sales', filter : true, width : '150px', grid : true},
                        // {fieldFind : '', key : 'tg_total', type: 'text', name : 'Target', filter : true, width : '150px', grid : true},
                        // {fieldFind : '', key : 'tg_realisasi', type: 'text', name : 'Realisasi', filter : true, width : '150px', grid : true},
                        // {fieldFind : '', key : 'tg_date', type: 'text', name : 'Y-M', filter : true, width : 'auto', grid : true},
                        // {fieldFind:'', key : 'tg_date', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                find : []
            },
            money: {"decimal": ",", "thousands": ".", "prefix": "", "suffix": "", "precision": 0, "masked": false},
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'g_id',
                'NAME' : 'g_name',
                'KETERANGAN' : 'g_keterangan'
            },
        }
    },
    methods : {
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            let params = {
                pfunction:'showall'
            }
            axios.post("sales/ApiPenjualan.php",params).then(function (response) {
                if(response.data.success==true){
                    self.recs = response.data.rec;
                }else{
                    console.log(response.data.rec);
                }
            });
        },
        async loadDetail(){
            let self = this;

            var vparams = {
                pfunction : 'showbyid',
                tg_id : self.rec.tg_id
            };
            axios.post("sales/ApiTargetDetail.php",vparams).then(function (response) {
                if(response.data.success==true){
                    self.recDetails = response.data.rec;
                }else{
                    console.log(response.data.messages);
                }
            });
        },
        async showDetail(dtl){

            let self = this;
            self.recsratiocust = [];
            axios.post("sales/ApiPenjualan.php",{
                    pfunction : "showcust",
                    tgi_sales : dtl.tp_sales,
                    tgi_name : dtl.ts_name
                }).then(function (response) {
                    self.recsratiocust = response.data.rec;
                    self.recnama = response.data.name;
                    $('#ratio').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: false,
                    deferRender: true,
                    autoWidth: false,
                    responsive: true,
                    data : self.recsratiocust,
                    columns: [
                        {"data": "1"},
                        {"data": "2",render: $.fn.dataTable.render.number( '.', ',', 2)},
                        {"data": "3",render: $.fn.dataTable.render.number( '.', ',', 2)},
                        {"data": "4",render: function(data) { return data + "%"; }}
                    ],
                    columnDefs: [
                    ],
                    order: [[ 1, "desc" ]],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" + "<'row'<'col-md-12 h9 mb-3'tr>>" + "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        {
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',
                        }
                    ],
                    // buttons: ['csv']
                });
                    });

        },
        removeDetail(idx, dtl) {
            let self = this;
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        dtl.pfunction = 'delete';
                        swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                    }
                }
            );
        },
        exportToExcel() {
            let self = this;
      // Ambil tabel yang ingin diexport
      self.recDetails.map(function(val){
        axios.post("sales/ApiDetail.php",{
            pfunction:'showbyid2',
            tgd_id : val['tgd_id'],
        }).then(function (recCus){
            val['cusST'] = recCus.data.rec;
            self.$forceUpdate();
            const worksheet = XLSX.utils.json_to_sheet(recCus.data.rec);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, val['cusST']);
            XLSX.writeFile(workbook, 'data.xlsx');
        })
      })
    },
                prints(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('Pilih data');
                return false;
            } else {
                moment.locale('id')
                self.rec['tg_date'] = moment(String(self.rec['tg_date'])).format("LL");
                self.recDetails.map(function(val){
                    axios.post("sales/ApiDetail.php",{
                        pfunction:'showbyid',
                        tgd_id : val['tgd_id'],
                    }).then(function (recCus) {
                        val['cusST'] = recCus.data.rec;
                        self.$forceUpdate();
                        document.getElementById('idLoading').style.display='block';
                        setTimeout(() => {
                            document.getElementById('idLoading').style.display='none';
                            // document.getElementById('cetakDetail').style.display='block';
                            let vhtml = document.getElementById('cetakDetail').innerHTML;
                            if(print)
                                self.printHtml(vhtml,'A4','portrait','5px 25px 5px 25px');
                            else
                                self.downloadFile('PO.xlsx',vhtml,'data:text/xls');
                        }, 1000);
                    });
                });
            }
        },
        printHtml(textHtml, kertas, bentuk, margin){
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if(element!=null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="./assets/css/w3.css">
                    <link rel="stylesheet" href="./assets/css/report.css">
                    <title>Document</title>
                    <style>
                    .pindah-halaman {page-break-after: always;}
                    @media print {
                        @page {
                            size: ${kertas} ${bentuk};
                            margin : ${margin};
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.log(textHtml);
            newWin.document.close();
        },

        removeTarget(){
            let self = this;
            var vparams = {
                tg_id : self.rec.tg_id,
                pfunction : "delete"
            };
            axios.post("sales/ApiTarget.php",vparams).then(function (response) {
                if (response.data.success == true) {
                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                    self.$delete(self.recs, self.selectedRow);
                }else{
                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                }
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },

        getSisa(){
            let self = this;
            self.changeGrandTotal();
            return self.rec.tg_sisa;
        },
        changeGrandTotal() {
            let self = this;
            let total = 0;
            self.recDetails.map(function(value) {
               total = value.tgd_total + total;
            });
            self.rec.tg_sisa = parseFloat(self.rec.tg_total) - total;
        },
        setSales(e){
            let self = this;
            self.changeGrandTotal();
            let ketemu = 0;
            self.recDetails.map(function(key) {
                if (key.tgd_sales == e) {
                    ketemu = ketemu+1;
                }
            });
            if (ketemu > 1) {
                swal({
                    title: "Rejected !",
                    text: "Double sales",
                    type: "warning",
                });
                self.rec.sales = ketemu;
                return false;
            }
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },

        setInit() {
            let self = this;
            axios.post("master/ApiSales.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.recsSales = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>